import { proxy } from 'valtio';

import { ShippingInfo } from '@/graphql/generated';
import { CheckoutDetails } from '@/types/CheckoutTypes';

export type CheckoutType = {
  details: CheckoutDetails;
  shippingAddress: ShippingInfo | null;
  scutisUsed: number;
};

const state = proxy<CheckoutType>({
  details: {
    subtotal: 0,
    shippingFee: 0,
    salesTax: 0,
    scutisUsed: 0,
    total: 0,
    scutisEarned: 0,
    extraScutis: 0,
    discounts: {
      cart: 0,
      shop: 0,
    }
  },
  scutisUsed: 0,
  shippingAddress: null,
});

const setState = (newState: Partial<CheckoutType>) => {
  if (newState.details) state.details = newState.details;
  if (newState.shippingAddress) state.shippingAddress = newState.shippingAddress;
  if (newState.scutisUsed) state.scutisUsed = newState.scutisUsed;
};

export const checkoutStore = {
  state,
  actions: { setState },
};
