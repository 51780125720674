import { Button } from '@/components/Common/Button';
import { Icon } from '@/components/Common/Icon';
import { useMyOrdersQuery, useMyRewardsQuery, useMyWalletQuery } from '@/graphql/generated';
import { appRoutes } from '@/helpers/routes.helper';
import { useQueryClient } from '@tanstack/react-query';
import { cartStore } from '@/store/cart.store';
import { useEffect } from 'react';

export function PaymentCompletePage() {
  const queryClient = useQueryClient();

  useEffect(() => {
    cartStore.actions.clearAll();

    queryClient.invalidateQueries({
      queryKey: [useMyOrdersQuery.getKey(), useMyRewardsQuery.getKey(), useMyWalletQuery.getKey()],
    });
  }, [queryClient]);

  return (
    <div className="flex h-full items-center justify-center">
      <div className="flex flex-col items-center text-center">
        <Icon name="CheckedStar" className="my-4 h-20 w-20" />
        <h1 className="my-4 font-semibold text-white">Your Order Has Been Placed</h1>
        <span className="text-light-grey mb-4 text-base font-medium">Thank you for purchasing with Scuti</span>
        <Button className="mt-4 w-full" href={appRoutes.store}>
          Continue Shopping
        </Button>
      </div>
    </div>
  );
}
