import { NavLink } from 'react-router-dom';
import { Button } from '@/components/Common/Button';
import { Modal } from '@/components/Common/Modal';
import { Welcome } from '@/components/Common/Welcome';
import { WelcomeLayout } from '@/components/Layout/WelcomeLayout';
import { appRoutes } from '@/helpers/routes.helper';

export function WelcomePage() {
  return (
    <WelcomeLayout>
      <Modal className="w-full flex-col	items-center justify-center bg-transparent md:w-[60%] md:bg-darker-gray/60 md:p-14">
        <Welcome />
        <div className="w-full">
          <Button className="mb-4 w-full" href={appRoutes.registration}>
            Create Account
          </Button>
          <Button variant="secondary" className="w-full" href={appRoutes.login}>
            Login
          </Button>
          <div className="mt-8 text-center">
            <NavLink to={appRoutes.store}>Continue to the Store</NavLink>
          </div>
        </div>
      </Modal>
    </WelcomeLayout>
  );
}
