import { Alert } from '@/components/Common/Alert';

type BlockReasonProps = {
  className?: string;
  reason: 'MissingAddress' | 'OutOfStock' | 'ShippingIssue';
};

export function BlockReason({ className, reason }: BlockReasonProps) {
  switch (reason) {
    case 'MissingAddress': {
      return (
        <Alert
          className={className}
          variant="error"
          heading="Shipping Address is missing."
          content="Please enter your shipping address to complete your order."
        />
      );
    }
    case 'OutOfStock': {
      return (
        <Alert
          className={className}
          variant="error"
          heading="Out of stock!"
          content="One or more products are now out of stock, please go back to your cart to fix them."
        />
      );
    }
    case 'ShippingIssue': {
      return (
        <Alert
          className={className}
          variant="error"
          heading="Shipping error."
          content="Sorry, one or more items in your cart can not be shipped to your location. We apologize for this inconvience and continue to expand our assortment."
        />
      );
    }
  }
}
