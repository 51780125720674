import { useEffect, useMemo } from 'react';
import Masonry from 'react-masonry-css';
import { useParams } from 'react-router-dom';
import { CategorySwitcher } from '@/components/Common/CategorySwitcher';
import { OfferCard } from '@/components/Common/OfferCard';
import { ScrollLoader } from '@/components/Common/ScrollLoader';
import { useCategory } from './CategoryPage.hooks';
import { useScreenSize } from '@/hooks/useScreenSize';
import { tourStore } from '@/store/tour.store';
import { useMe } from '@/hooks/useMe';

export function CategoryPage() {
  const { category } = useParams<{ category: string }>();
  const { offers, hasNext, isLoading, onLoadNext, onLeft, onRight } = useCategory(category!);
  const me = useMe();

  const { setTourState } = tourStore.actions;
  const { forceRun } = tourStore.state;

  const screenSize = useScreenSize();

  const columns = useMemo(() => {
    switch (screenSize) {
      case 'xs':
        return 1;
      case 'sm':
        return 2;
      case 'md':
        return 2;
      case 'lg':
        return 3;
      case 'xl':
      case '2xl':
        return 4;
      case '3xl':
        return 5;
    }
    return 6;
  }, [screenSize]);

  useEffect(() => {
    setTourState(false);
    let timeout = window.setTimeout(() => {
      if (offers.length > 0) {
        const tourStatus = forceRun || (!!me && !me?.welcomeTourCompleted);
        setTourState(tourStatus, 1);
      }
    }, 750);

    return () => {
      window.clearTimeout(timeout);
    };
  }, [offers, setTourState, me, forceRun]);

  return (
    <>
      <div className="mt-4 flex w-full flex-col items-center px-2 md:px-0 lg:mt-8">
        <Masonry breakpointCols={columns} className="flex flex-row space-x-4" columnClassName="scuti-masonry">
          {offers.map((offer) => (
            <OfferCard
              key={offer.id}
              offer={offer}
              className="joyride-offer mb-4 overflow-hidden rounded-2xl"
              fullWidth={['xs', 'sm', 'md', 'lg', 'xl'].some((s) => s === screenSize)}
            />
          ))}
        </Masonry>
        <CategorySwitcher
          className="category-switcher fixed bottom-4 md:bottom-8"
          category={category!}
          onLeft={onLeft}
          onRight={onRight}
        />
      </div>
      {hasNext && <ScrollLoader isLoading={isLoading} onLoad={onLoadNext} />}
    </>
  );
}
