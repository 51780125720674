import { NavLink } from 'react-router-dom';
import { OfferListItem } from '@/types/OfferTypes';
import { getOfferLink } from '@/utils/offer.utils';
import { Icon } from './Icon';
import { Image } from './Image';
import { useImpressionMetric } from '@/helpers/metrics/useImpressionMetric';
import { useInView } from 'react-intersection-observer';
import { CampaignType } from '@/graphql/generated';
import { BannerTile } from './BannerTile';
import clsx from 'clsx';
import { formatScore, getLowerDecimal, getUpperDecimal } from '@/utils/number.utils';

export interface OfferCardProps {
  className?: string;
  offer: OfferListItem;
  fixedHeight?: boolean;
  fullWidth?: boolean;
}

export function OfferCard({ className, offer, fixedHeight, fullWidth = false }: OfferCardProps) {
  const { inView, ref } = useInView({
    delay: 100,
    trackVisibility: true,
    triggerOnce: true,
    threshold: 0.55,
  });

  useImpressionMetric({ offerId: offer.id, type: offer.type, adSize: offer.adSize }, inView);

  const target = offer.pcLink ? '_blank' : undefined;
  const isColumn6Ad = offer.type === CampaignType.Column6Ad;

  return (
    <div className={clsx(`w-[300px] cursor-pointer`, className)} ref={ref}>
      <NavLink to={getOfferLink(offer)} target={target} state={{ id: offer.id }} replace>
        {isColumn6Ad ? (
          <BannerTile url={offer.image} id={offer.id} adSize={offer.adSize} />
        ) : (
          <Image
            className={clsx(
              `flex w-[300px] justify-center bg-white object-cover`,
              { 'h-[250px]': fixedHeight },
              { 'h-m-[8rem]': !fixedHeight },
            )}
            src={offer.image}
          />
        )}
        <div className="flex flex-row items-center justify-between rounded-tr-lg bg-white text-black">
          <div className="flex flex-row items-center text-xs font-bold">
            <img
              alt=""
              className="mr-1 h-10 w-10 shrink-0 object-cover xl:h-11 xl:w-11"
              src={offer.shopThumbnail}
              loading="lazy"
            />
            <div className="line-clamp-2 h-5 py-1 text-[9px] uppercase">{offer.title}</div>
          </div>
          {!offer.scutisExclusive && !isColumn6Ad ? (
            <div className="flex items-center pl-1">
              {offer.price && (
                <div className="text-xl font-extrabold leading-none text-dark-gray">
                  ${getUpperDecimal(offer.price)}
                  <sup>{getLowerDecimal(offer.price)}</sup>
                </div>
              )}
              {offer.scutiRewards && (
                <div className="joyride-rewards ml-2 flex-row rounded-xl rounded-tr-none border-2 border-yellow bg-[#333] p-1 italic leading-none text-white lg:border-3">
                  <div className="block text-right text-lg font-black leading-none">
                    {formatScore(offer.scutiRewards)}
                  </div>
                  <div className="text-[0.6rem] font-medium uppercase leading-none lg:text-[0.7rem]">rewards</div>
                </div>
              )}
            </div>
          ) : (
            <Icon name="ChevronRight" className="mr-2 h-2 xl:h-3" />
          )}
        </div>
      </NavLink>
    </div>
  );
}
