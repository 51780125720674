import {
  ShippingInfo,
  useCartCalculationMutation,
  useMyCartQuery,
  useStripeCheckoutMutation,
} from '@/graphql/generated';
import { appRoutes } from '@/helpers/routes.helper';
import { appSessionStorage } from '@/helpers/storage.helper';
import { useMe } from '@/hooks/useMe';
import { useStoreInfo } from '@/hooks/useStoreInfo';
import { cartStore } from '@/store/cart.store';
import { checkoutStore } from '@/store/checkout.store';
import { CalculatedCartItem } from '@/types/CartTypes';
import { CheckoutDetails } from '@/types/CheckoutTypes';
import { getErrorMessage } from '@/utils/error.utils';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { sendMessage } from '@/helpers/sdk-wrapper';
import { getVuplexPaymentLink } from '@/helpers/sdk-wrapper/utils';

type Return = {
  details: CheckoutDetails;
  shippingAddress: ShippingInfo | null;
  scutisUsed: number;
  blockReason?: 'MissingAddress' | 'OutOfStock' | 'ShippingIssue';
  handleCheckout: () => Promise<void>;
};

export function useCheckout(): Return {
  const navigate = useNavigate();
  const me = useMe();
  const { platform } = useStoreInfo();
  const [cartItems, setCartItems] = useState<CalculatedCartItem[]>([]);
  const [blockReason, setBlockReason] = useState<Return['blockReason']>();
  const queryClient = useQueryClient();

  const { products, promoCodes } = cartStore.state;
  const { scutisUsed, details, shippingAddress } = checkoutStore.state;

  const shippingInfo = useMemo(() => shippingAddress || me?.shippingInfo, [me?.shippingInfo, shippingAddress]);

  const calculateCartRequest = useCartCalculationMutation().mutateAsync;

  useEffect(() => {
    if (shippingInfo) {
      calculateCartRequest(
        {
          input: {
            gameId: appSessionStorage.getItem('gameId')!,
            items: Object.values(products).map(({ product, quantity }) => ({
              offerId: product.variant.offerId,
              quantity,
              variantId: product.variant.variantId,
            })),
            shippingInfo: {
              ...shippingInfo,
              address2: shippingInfo.address2 || '',
            },
            promoCodes,
          },
        },
        {
          onSuccess: ({ calculateCart }) => {
            const { breakdown, items, extraRewards } = calculateCart;

            const shippingIssue = items.some(({ products }) =>
              products.some(({ country }) => shippingInfo.country !== country),
            );

            if (shippingIssue) setBlockReason('ShippingIssue');

            const scutisEarned = items.reduce((sum, { products }) => {
              return sum + products.reduce((s, p) => (p.reward.scutis || 0) + s, 0);
            }, 0);

            // there is a possibility total is < 0 if a promo code is used and amount is paid in full
            // with scutis, so calculate the total and then set the value taking this into account
            const total = breakdown.total - scutisUsed / platform.scutiExchangeRate;

            checkoutStore.actions.setState({
              details: {
                subtotal: breakdown.total - breakdown.tax - breakdown.shipping + extraRewards.discounts.cart,
                shippingFee: breakdown.shipping,
                salesTax: breakdown.tax,
                scutisUsed: scutisUsed,
                total: total > 0 ? total : 0,
                scutisEarned,
                extraScutis: extraRewards.scutis,
                discounts: extraRewards.discounts,
              },
              shippingAddress: shippingInfo,
            });
            setCartItems(items);
          },
          onError: async (error) => {
            toast.error(getErrorMessage(error), { autoClose: 8000 });
            await queryClient.refetchQueries({ queryKey: useMyCartQuery.getKey() });
            navigate(appRoutes.cart);
          },
        },
      );
    } else {
      setBlockReason('MissingAddress');
    }
  }, [
    calculateCartRequest,
    platform.scutiExchangeRate,
    products,
    promoCodes,
    scutisUsed,
    shippingInfo,
    navigate,
    queryClient,
  ]);

  const stripeCheckoutRequest = useStripeCheckoutMutation().mutateAsync;

  const handleCheckout = async () => {
    const { phone, ...shippingInfo } = shippingAddress!;

    const items = cartItems
      .map(({ products }) => {
        return products.map(({ offerId, variantId, quantity }) => ({ offerId, variantId, quantity }));
      })
      .reduce((acc, next) => [...acc, ...[...next]], []);

    const { stripeCheckout } = await stripeCheckoutRequest({
      input: {
        gameId: appSessionStorage.getItem('gameId')!,
        items,
        shippingInfo: {
          ...shippingInfo,
          address2: shippingInfo.address2 || '',
        },
        scutisToApply: scutisUsed || undefined,
        promoCodes,
      },
    });

    if (stripeCheckout?.clientSecret) {
      appSessionStorage.setItem('stripeKey', stripeCheckout.clientSecret);
      if (appSessionStorage.getItem('platform') === 'Vuplex') {
        sendMessage({
          message: 'PAYMENT',
          payload: { url: getVuplexPaymentLink(stripeCheckout.clientSecret) },
        });
      }
      navigate(appRoutes.payment);
    } else if (stripeCheckout?.paidInFullWithScutis) {
      navigate(appRoutes.paymentComplete);
    }
  };

  return { scutisUsed, details, shippingAddress, blockReason, handleCheckout };
}
