import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from '@/components/Common/Button';
import { Icon } from '@/components/Common/Icon';
import { OffersRow } from '@/components/Common/OffersRow';
import { ProductDescription } from '@/components/Common/ProductDescription';
import { ProductImages } from '@/components/Common/ProductImages';
import { ProductVariants } from '@/components/Common/ProductVariants';
import { appRoutes } from '@/helpers/routes.helper';
import { useRecommendedItems } from '@/hooks/useRecommendedItems';
import { cartStore } from '@/store/cart.store';
import { cleanHtml } from '@/utils/htmlMarkup.utils';
import { SelectedOptions } from '@/utils/offer.utils';
import { useProductOffer } from './ProductOfferPage.hooks';
import { Disclosure } from '@/components/Common/Disclosure';
import { useEngagementProductMetric } from '@/helpers/metrics/useEngagementProductMetric';
import { QuantitySelector } from '@/components/Common/QuantitySelector';
import { appLocalStorage, appSessionStorage } from '@/helpers/storage.helper';
import { useCartItemsMetrics } from '@/helpers/metrics/useCartMetric';
import { mapProductOfferToUserCartProduct } from '@/utils/cart.utils';
import { useSocializationBrandMetric } from '@/helpers/metrics/useSocializationBrandMetric';
import { InstantCheckoutModal } from '@/components/Common/InstantCheckout';
import { useScrollIntoView } from '@/hooks/useScrollIntoView';
import { useStoreInfo } from '@/hooks/useStoreInfo';

export function ProductOfferPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  useEngagementProductMetric(id!);

  const socializationBrand = useSocializationBrandMetric();
  const { addToCartMetric } = useCartItemsMetrics();

  const { platform } = useStoreInfo();

  const [pageId, setPageId] = useState<string>(id!);
  const [selected, setSelected] = useState<SelectedOptions | undefined>();

  const [quantity, setQuantity] = useState<number>(1);
  const [instantCheckoutOpen, setInstantCheckoutOpen] = useState(false);

  const validatedSelected = useMemo(() => {
    if (pageId !== (location.state || { id: undefined }).id) {
      return undefined;
    }
    return selected;
  }, [pageId, location.state, selected]);

  useEffect(() => {
    if (id !== pageId) {
      setPageId(id!);
      setSelected(undefined);
      setQuantity(1);
    }
  }, [id, pageId, quantity]);

  const { productOffer, selectedOptions, optionsMatrix } = useProductOffer(id!, validatedSelected);
  const { recommendedItems } = useRecommendedItems(productOffer.shop.name);
  const recommended = recommendedItems.filter((r) => r.id !== id);
  const scutisExclusive = productOffer.scutisExclusive;

  const handleShare = () => {
    const url = `${location.pathname}?gameId=${appSessionStorage.getItem('gameId')}`;
    if (navigator.share) {
      navigator.share({ url });
      socializationBrand(id!);
    } else {
      navigator.clipboard.writeText(url);
      socializationBrand(id!);
      toast('Link has been copied to clipboard', { type: 'success', icon: <Icon name="Information" /> });
    }
  };

  const handleAddToCart = useCallback(async () => {
    if (!appLocalStorage.getItem('refreshToken')) return navigate(appRoutes.welcome);
    const product = mapProductOfferToUserCartProduct(productOffer, quantity, platform.scutiExchangeRate);
    await cartStore.actions.add(product, quantity);
    addToCartMetric({ product, quantity });

    navigate(appRoutes.cart);
  }, [addToCartMetric, navigate, productOffer, quantity, platform.scutiExchangeRate]);

  const handleInstantCheckout = useCallback(async () => {
    if (!appLocalStorage.getItem('refreshToken')) return navigate(appRoutes.welcome);
    setInstantCheckoutOpen(true);
  }, [navigate]);

  const ref = useRef<HTMLDivElement>(null);

  useScrollIntoView(ref, productOffer.id);

  return (
    <>
      {instantCheckoutOpen && (
        <InstantCheckoutModal
          product={productOffer}
          quantity={quantity}
          onClose={() => setInstantCheckoutOpen(false)}
        />
      )}
      <div className="mt-8 md:px-4 xl:px-40">
        <div className="mb-4 flex w-full flex-row flex-wrap md:mb-8">
          <div ref={ref} className="flex w-full items-start md:w-1/3">
            <ProductImages className="w-full" images={productOffer.images} />
          </div>
          <div className="w-full md:w-2/3 md:pl-8">
            <ProductDescription productOffer={productOffer} onShare={handleShare} onAddToCart={handleAddToCart} />
            <div className="wrap columns mt-8 flex w-full flex-row flex-wrap">
              <ProductVariants
                productOffer={productOffer}
                selectedOptions={selectedOptions}
                optionsMatrix={optionsMatrix}
                onSelect={setSelected}
              />
              {!scutisExclusive ? (
                <QuantitySelector
                  className="mt-4"
                  label="Quantity"
                  max={productOffer.inStock}
                  onChange={(value) => setQuantity(value)}
                  value={quantity}
                  step={1}
                />
              ) : null}
            </div>
            <div className="mt-8 flex flex-row items-center justify-between space-x-4 md:justify-start">
              {scutisExclusive ? (
                <Button className="w-full md:w-72" onClick={handleInstantCheckout}>
                  Buy Now With Scuti$ <Icon name="Cart" className="ml-2 h-4" />
                </Button>
              ) : (
                <Button className="w-full md:w-72" onClick={handleAddToCart}>
                  Add to cart <Icon name="Cart" className="ml-2 h-4" />
                </Button>
              )}

              <Button className="hidden w-full md:flex md:w-72" variant="secondary" onClick={handleShare}>
                Share <Icon name="ShareC" className="ml-2 h-4" />
              </Button>
            </div>
          </div>
        </div>
        <Disclosure title="Description" defaultOpen>
          <p
            className="md:col product-description col-span-6 w-full overflow-y-auto md:inline"
            dangerouslySetInnerHTML={{
              __html: cleanHtml(productOffer.description || ''),
            }}
          />
        </Disclosure>

        {!!recommended.length && (
          <div className="-mt-[1px]">
            <Disclosure title="Recommended Items" defaultOpen>
              <OffersRow offers={recommended} id={1} />
            </Disclosure>
          </div>
        )}
      </div>
    </>
  );
}
