import { MouseEventHandler, useCallback } from 'react';
import { useLocation } from 'react-use';
import { appRoutes } from '@/helpers/routes.helper';
import { Icon } from '@/components/Common/Icon';
import { sendMessage } from '@/helpers/sdk-wrapper';
import { matchPath, useNavigate } from 'react-router-dom';
import { appSessionStorage } from '@/helpers/storage.helper';
import { useCloseMetric } from '@/helpers/metrics/useCloseMetric';
import { wrapperStore } from '@/store/wrapper.store';
import { useSnapshot } from 'valtio/react';

/**
To directly send to home page, instead of previous-page.
*/
export const pagesDirectToHome = [appRoutes.legalTermsAndConditions, appRoutes.legalPrivacyPolicy];

export function Back() {
  const { pathname = '', state } = useLocation();

  const { labels } = useSnapshot(wrapperStore.state.ui);

  const navigate = useNavigate();
  const sendCloseMetric = useCloseMetric();

  const isStart = matchPath(appRoutes.store, pathname);
  const hideBackToTheGame = appSessionStorage.getItem('hideBackToTheGame') || !appSessionStorage.getItem('platform');

  const label = isStart ? labels.backToGame : 'BACK';

  const handleBack: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.preventDefault();

      if (isStart) {
        sendCloseMetric();
        return sendMessage({ message: 'BACK_TO_THE_GAME' });
      }

      return state.idx === 0 || pagesDirectToHome.includes(pathname) ? navigate(appRoutes.store) : navigate(-1);
    },
    [isStart, navigate, sendCloseMetric, state.idx],
  );

  return isStart && hideBackToTheGame ? null : (
    <button
      className={`flex items-center rounded-full bg-dark-gray px-2 py-1 text-[9px] font-bold hover:bg-darker-gray disabled:bg-gray disabled:text-light-gray md:px-3 md:py-2 lg:px-3 lg:py-[6px] lg:text-base`}
      onClick={handleBack}
    >
      <Icon name={isStart ? 'Cross' : 'ArrowLeft'} className="mr-1 h-3 md:mr-2 lg:h-4" /> <span>{label}</span>
    </button>
  );
}
