import { forwardRef, HTMLProps, useMemo, useState } from 'react';
import { Icon } from './Icon';
import { Tooltip } from './Tooltip';
import { CleaveOptions } from 'cleave.js/options';

interface InputProps
  extends Pick<
    HTMLProps<HTMLInputElement>,
    'name' | 'placeholder' | 'onBlur' | 'onChange' | 'onFocus' | 'disabled' | 'maxLength' | 'defaultValue'
  > {
  type?: 'text' | 'email' | 'password' | 'number' | 'tel';
  value?: string | null;
  label?: string;
  required?: boolean;
  error?: string;
  hint?: string;
  tooltip?: string;
  options?: CleaveOptions;
  onKeyDown?: (e: React.KeyboardEvent) => void;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(function TextField(props, ref) {
  const {
    value,
    placeholder,
    label,
    type = 'text',
    required,
    disabled,
    error,
    hint,
    maxLength,
    onChange,
    onBlur,
		tooltip
  } = props;
  const [passwordVisible, setPasswordVisible] = useState(false);

  const isEmpty = (value?.length || 0) > 0;

  const borderStyle = useMemo(() => {
    if (error) return 'border-red';
    if (!isEmpty) return 'border-transparent hover:border-blue focus:border-blue';
    return 'border-black hover:border-blue focus:border-blue';
  }, [error, isEmpty]);

  return (
    <div className="flex w-full flex-col">
      <label className="text-xs text-light-gray flex w-full z-10" htmlFor={`input-${label}`}>
        <div className="flex grow">
					<span>
						{label}
						{required && <sup className="sup text-paragraph-sm text-red">*</sup>}
					</span>
				</div>
        {tooltip && <Tooltip icon={<Icon name="Information" className="h-4 text-white self-end" />} tooltip={tooltip} />}
      </label>
      <div className="relative flex w-full flex-grow flex-row items-center z-20">
        <input
          ref={ref}
          autoComplete="off"
          className={`w-full border-0 border-b bg-transparent px-0 py-4 focus:outline-none ${borderStyle}
                        paragraph-lg text-lg text-white placeholder:text-gray invalid:text-red focus:ring-0 focus:invalid:text-white ${
                          !passwordVisible && type === 'password' && isEmpty
                            ? 'password-hidden tracking-widest'
                            : 'tracking-normal'
                        }`}
          type={passwordVisible ? 'text' : type}
          name={`input-${label}`}
          maxLength={maxLength}
          placeholder={placeholder}
          value={value || ''}
          disabled={disabled}
          onBlur={onBlur}
          onChange={onChange}
        />

        {type === 'password' && (
          <div className="absolute right-0 p-3 pr-0" onClick={() => setPasswordVisible(!passwordVisible)}>
            {passwordVisible ? (
              <Icon name="NotVisible" className="h-5 w-5 text-white" />
            ) : (
              <Icon name="Visible" className="h-5 w-5 text-white" />
            )}
          </div>
        )}
      </div>

      {hint && (value?.length || 0) === 0 && <p className="disclaimer mt-2 text-light-gray">{hint}</p>}

      {error && <p className="disclaimer mt-2 text-red">{error}</p>}
    </div>
  );
});
