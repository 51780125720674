import Joyride, { ACTIONS, CallBackProps, EVENTS, Step, TooltipRenderProps } from 'react-joyride';
import { useMe } from '@/hooks/useMe';
import { useEffect } from 'react';
import { tourStore } from '@/store/tour.store';
import { useSnapshot } from 'valtio';
import { useSetWelcomeTourCompletedMutation } from '@/graphql/generated';
import { Button } from './Button';

const steps: Step[] = [
  {
    target: '.category-row-0',
    disableBeacon: true,
    disableOverlayClose: true,
    placement: 'bottom',
    content: <>Discover currated deals and products just for you. Click on a Category to see more.</>,
  },
  {
    target: '.joyride-offer',
    disableBeacon: true,
    disableOverlayClose: true,
    placement: 'top',
    content: <>Shop and earn up to 5% Scuti rewards on all purchases.</>,
  },
  {
    target: '.joyride-offer .joyride-rewards',
    disableBeacon: true,
    disableOverlayClose: true,
    placement: 'top',
    content: <>Scuti rewards earned for purchasing each item.</>,
  },
  {
    target: '#joyride-wallet',
    disableBeacon: true,
    disableOverlayClose: true,
    placement: 'top',
    content: <>Your Scuti wallet lets you know your rewards balance to use in game or on purchases.</>,
  },
  {
    target: '.joyride-exchange',
    disableBeacon: true,
    disableOverlayClose: true,
    placement: 'top',
    content: <>Decide how many scuti rewards to exhcange into your game currency and level up your game instantly.</>,
  },
];

export function CustomTooltipComponent({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
  isLastStep,
}: TooltipRenderProps) {
  return (
    <div className={`w-full max-w-md p-12 text-center`} {...tooltipProps}>
      {step.title && <span className="text-2xl">{step.title}</span>}
      <div className={`w-full text-xl`}>{step.content}</div>
      {!step.hideFooter && (
        <div className="my-4 flex justify-center text-xs">
          {continuous && (
            <Button {...primaryProps} id={isLastStep ? 'close' : 'next'}>
              {isLastStep ? 'CLOSE' : 'NEXT'}
            </Button>
          )}
          {!continuous && (
            <Button {...closeProps} id="close">
              CLOSE
            </Button>
          )}
        </div>
      )}
    </div>
  );
}

export function GuidedTour() {
  const me = useMe();
  const setWelcomeTourCompleted = useSetWelcomeTourCompletedMutation();

  const { run, stepIndex, forceRun } = useSnapshot(tourStore.state, { sync: true });

  const { setTourState, setForceRun } = tourStore.actions;

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, lifecycle, type } = data;

    if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

      if (index === 0) {
        const el = document.querySelector('#category-title-0') as HTMLButtonElement;

        el && el.click();

        return setTourState(false, nextStepIndex);
      }

      if ([1, 2].includes(index)) {
        setTourState(true, nextStepIndex);
      }

      if (index === 3) {
        const el = document.querySelector('#joyride-wallet a') as HTMLButtonElement;

        el && el.click();

        return setTourState(false, nextStepIndex);
      }

      // handles normal completion of tour
      if (lifecycle === 'complete' && index === 4) {
        setTourState(false);
        setForceRun(false);
        setWelcomeTourCompleted.mutateAsync({});
      }

      // edge-case for browsers/games where exchange (and target element) are not available
      if (type === EVENTS.TARGET_NOT_FOUND && index === 4) {
        setTourState(false);
        setForceRun(false);
        setWelcomeTourCompleted.mutateAsync({});
      }
    }
  };

  useEffect(() => {
    const tourStatus = forceRun || (!!me && !me?.welcomeTourCompleted);
    setTourState(tourStatus);
  }, [me, setTourState, forceRun]);

  return (
    <>
      <Joyride
        tooltipComponent={CustomTooltipComponent}
        callback={handleJoyrideCallback}
        continuous
        hideCloseButton
        disableScrolling
        hideBackButton
        disableCloseOnEsc
        spotlightPadding={0}
        stepIndex={stepIndex}
        run={run}
        steps={steps}
        styles={{
          options: {
            zIndex: 10000,
            overlayColor: 'rgba(0, 0, 0, 0.95)',
          },
        }}
      />
    </>
  );
}
